import React from "react";
import { useLocation } from "react-router-dom";

const ContactLists = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const companyId = queryParams.get("idEmpresa");

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={`https://sistemashep.com.br/lista_contatos/?idEmpresa=${companyId}`}
        style={{
          width: "100%",
          height: "calc(100vh - 64px)", // Altura ajustada para layout
          border: "none",
        }}
        title="Listas de Contatos"
      ></iframe>
    </div>
  );
};

export default ContactLists;
