import React from "react";
import { useLocation } from "react-router-dom";

const Campaigns = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const companyId = queryParams.get("idEmpresa");

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={`https://sistemashep.com.br/campanhas/?idEmpresa=${companyId}`}
        style={{
          width: "100%",
          height: "calc(100vh - 64px)", // Ajuste para considerar menus ou cabeçalhos
          border: "none",
        }}
        title="Campanhas"
      ></iframe>
    </div>
  );
};

export default Campaigns;